import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/x-ray-poster.jpeg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1982 slasher movie, X-Ray aka Hospital Massacre"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 33</h1>
                    <h2>X-Ray</h2>
                    <h3>February 5, 2024</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <p><b>Director:</b> Boaz Davidson // <b>Starring:</b> Barbi Benton</p>
                        <div className="player">
                        <iframe src="https://podcasters.spotify.com/pod/show/bring-me-the-axe/embed/episodes/33-X-Ray-wguests-Jonny-Atkinson-and-Aileen-Clark-e2fcee9/a-aau8muo" height="102px" width="400px" frameborder="0" scrolling="no" title="Bring Me The Axe! horror podcast episode 33, X-Ray"></iframe>
                        </div>
                        <p><Link to="/transcripts/x-ray">Read the episode transcript</Link></p>
                        <p>This week Bryan and Dave are joined by Jonny and Aileen from the Uy Que Horror podcast for a maddening breakdown of the Cannon Films deep cut, X-Ray (aka Hospital Massacre), starring Barbi Benton as a woman who just needs to pick up her check-up results and spends the rest of the day in a Franz Kafka nightmare, navigating a hospital staffed by intense, antagonistic hospital staff and a masked murderer from her past. This whole movie is bonkers.</p>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/ganja-and-hess">Next Episode</Link></li>
                        <li><Link to="/episodes/fright-night">Previous Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)